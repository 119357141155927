<template>
  <div class="page">
    <navTop title="待解决历史遗留问题项目" :leftIcon="true"></navTop>
    <!-- <div class="home-title">待解决历史遗留问题项目</div> -->
    <!-- 内容 -->
    <div v-if="!showPass" class="home-sub">
      <div @click="showTab">
        {{categoryTabText}}
         <van-icon name="arrow-down" color="#fff" v-if="!show"/>
         <van-icon name="arrow-up" color="#fff" v-if="show"/>
      </div>
      <div class="mask" v-if="show" @click.stop>
        <div class="home-sub-box">
          <div class="home-tab">
            <div
              class="home-tab-item"
              :class="current == item.id ? 'home-item-select' : ''"
              v-for="(item, index) in tabList"
              :key="index"
              @click.stop="setCurrent(item.id)"
            >
            <van-icon v-if="item.flag == 'hot'" class="badge-icon" name="fire" color="#ee0a24" />
              {{ item.name }}
            </div>
          </div>
          <div class="home-sub-btn">
            <div class="cancel" @click="showTab">取消</div>
            <div class="sure" @click="sureBtn">确定</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!showPass" class="home">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <div>
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="companyPageList"
            class="business-list"
          >
            <div
              class="business-item"
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="business-item-title">{{ item.name }}</div>
              <div class="business-item-num">{{ item.input_money }}</div>
              <div class="business-item-name">挂点领导：{{ item.leader }}</div>
              <div class="business-item-btn">
                <div class="business-item-msg">{{ item.category_name }}</div>
                <div class="business-item-look" @click="goDetails(item.id)">
                  <img src="../assets/imgs/next_right.png" class="next-icon" />
                  点击查看
                </div>
              </div>
                <van-icon v-if="item.category.flag == 'hot'" class="list-icon" name="fire" color="#ee0a24" />
            </div>
          </van-list>
        </div>
      </van-pull-refresh>
    </div>
    <VerifyPass @pass-true="showPass = !showPass"></VerifyPass>
  </div>
</template>

<script>
import { projectPageList, getProjectCategory } from "@/api/index";
import navTop from "@/components/common/navTop.vue";
import VerifyPass from "@/components/common/verifyPass.vue";
export default {
  components: {
    navTop,VerifyPass
  },
  data() {
    return {
      showPass: true,
      page: 1,
      pages: 1,
      type: 0,
      current: 0,
      tabList: [],
      show: false,
      list: [],

      loading: false,
      finished: false,
      refreshing: false,
      categoryTabText: "项目类型"
    };
  },
  watch:{
    showPass(newVal, oldVal) {
      if(!newVal) {
        this.getSubCategory();
        this.initCompanyList();
      }
    }
  },
  created() {
    this.type = this.$route.query.type;
    // this.companyPageList();
    // this.getSubCategory();
  },
  methods: {
    // 项目列表
    async companyPageList() {
      if (this.page > this.pages) {
        this.finished = true;
        return false;
      }
      let params = {
        page: this.page,
        type: this.type,
        category_id: this.current,
      };
      this.loading = true;
      try {
        const { code, data } = await projectPageList(params);
        let list = this.list.concat(data.data);
        this.list = list;
        this.page++;
        this.pages = data.last_page;
        this.loading = false;
        this.refreshing = false;
      } catch (e) {
        console.log(e);
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.initCompanyList();
    },
    goDetails(id) {
      this.$router.push({
        path: "/details",
        query: {
          id: id,
        },
      });
    },
    async getSubCategory() {
      try {
        const { code, data } = await getProjectCategory();
        this.tabList = [{ id: 0, name: "全部" }].concat(data);
      } catch (e) {
        console.log(e);
      }
    },
    sureBtn() {
      this.initCompanyList();
      this.tabList.forEach(element => {
        if(element.id === this.current) {
          this.categoryTabText = element.name
        }
      });
      this.showTab();
    },
    // 初始化列表
    initCompanyList() {
      this.page = 1;
      this.pages = 1;
      this.list = [];
      this.companyPageList();
    },
    showTab() {
      this.show = !this.show;
    },
    setCurrent(e) {
      this.current = e;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.home-title {
  color: #000;
  font-size: 17px;
  margin-bottom: 10px;
  font-weight: 700;
  padding-left: 20px;
}
.home-sub {
  /* border: 1px solid #dbdeee; */
  background-color: #556efe;
  padding-right: 20px;
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  position: relative;
  text-align: right;
}
.business-next-down {
  width: 13px;
  height: 10px;
}
.business-item {
  background-color: #f3f3f3;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
}
.business-item-title {
  font-size: 15px;
  margin-bottom: 10px;
  color: #333;
  font-weight: 700;
}
.business-item-num {
  font-size: 18px;
  color: #556efe;
  font-weight: 600;
  margin-bottom: 10px;
}
.business-item-name {
  font-size: 14px;
  margin-bottom: 5px;
  color: #000;
  font-weight: 500;
}
.business-item-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.business-item-msg {
  color: #fff;
  background-color: #556efe;
  font-size: 14px;
  padding: 5px 10px;
}
.business-item-look {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #556efe;
}
.next-icon {
  width: 8px;
  height: 10px;
  margin-right: 4px;
  margin-left: 7px;
}
.mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  z-index: 10;
  height: 100vh;
}
.home-sub-box {
  padding: 15px;
  background-color: #fff;
}
.home-sub-btn {
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 25px;
  margin-top: 10px;
}
.cancel {
  flex: 1;
  color: #666;
  text-align: center;
}
.sure {
  flex: 1;
  text-align: center;
  color: #556efe;
}
.home-tab {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.home-tab-item {
  background-color: #f3f3f3;
  border-radius: 15px;
  padding: 5px 10px;
  line-height: 22px;
  font-size: 14px;
  min-width: 75px;
  margin-bottom: 10px;
  margin-right: 10px;
  color: #666;
  text-align: center;
  position: relative;
}
.home-tab .home-item-select {
  border: 1px solid #eee;
  color: #556efe;
  background-color: #fff;
}
.badge-icon{
  position: absolute;
  right: -2px;
  bottom: 22px;
}
.list-icon{
  position: absolute;
  right: 8px;
  top: 8px;
}
</style>
